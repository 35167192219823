import React from "react";
import "../styles.css";
import "../atoms/styles-atoms.css";
import AngeboteMoafinder from "./AngeboteMoafinder";

const MoafinderSearch = () => {
  return (
    <>
      <AngeboteMoafinder />
    </>
  );
};

export default MoafinderSearch;
